import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { navLinks } from '../model/constants';
import { logo } from '../assets/assets';
import { motion } from 'framer-motion';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navbar = () => {
  const [active, setActive] = useState('');
  const [toggle, setToggle] = useState(false);
  const navLinksAll = navLinks.filter((nv)=> nv.id !== 'home');
  useEffect(() => {
    const handleScroll = (entries:any) => {
      entries.forEach((entry:any) => {
        if (entry.isIntersecting) {
          const { id } = entry.target;
          if(id==='home'){
            setActive('');
            window.history.replaceState(null, '', '/');
            return;
          }
          const activeLink = navLinks.find((link) => link.id === id)?.title;  
            activeLink ? setActive(activeLink) : setActive('');
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust threshold for sensitivity
    };

    const observer = new IntersectionObserver(handleScroll, observerOptions);

    // Observe each section by ID
    navLinks.forEach((link) => {
      const section = document.getElementById(link.id);
      if (section) observer.observe(section);
    });

    return () => observer.disconnect(); // Clean up observer on unmount
  }, []);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at calc(100% - 40px) 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(30px at calc(100% - 40px) 40px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };
  return (
    <nav
      className={`pl-8 pr-8 w-full flex items-center py-1 fixed 
      top-0 z-20 bg-navbar sm:opacity-[0.97] xxs:h-[12vh]`}>
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive('');
            window.scrollTo(0, 0);
          }}>
          <img
            src={logo} // your logo comes here
            alt="logo"
            className="sm:w-[50px] sm:h-[50px] w-[45px] h-[45px] object-contain"
          />

        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-8 mt-2">
          {navLinksAll.map((nav) => (
            <a key={nav.id} href={`#${nav.id}`}>
            <li
              className={`${
                active === nav.title ? 'text-primary' : 'text-butterCream'
              } text-[21px] font-medium font-mova 
                uppercase tracking-[3px] cursor-pointer nav-links`}
              onClick={() => setActive(nav.title)}>
              {nav.title}
            </li>
            </a>
          ))}
        </ul>

        {/* mobile */}
        <div 
          className="sm:hidden flex flex-1 w-screen justify-end items-center">
          {toggle ? (
            <motion.div 
              variants={sidebar} 
              initial="closed"
              animate={toggle ? 'open' : 'closed'}
              className={`px-6 pb-6 pt-4 bg-mobileNavbar opacity-[0.98] absolute 
                top-0 right-0 w-[80%] h-[60vh] z-10 menu menu-bg-radius ${
                  toggle ? 'menu-open' : 'menu-close'
                }`}>
              <div className="flex justify-end">
               <FontAwesomeIcon 
                  className="text-primary mr-2 text-[1.8em] cursor-pointer z-20"  
                  onClick={() => setToggle(!toggle)} icon={faXmark} 
                />
              </div>
              <div className='flex justify-end'>
              <ul
                className="list-none flex flex-col
                items-end justify-end mt-[2.5em] ">
                {navLinksAll.map((nav) => (
                  <li
                    id={nav.id}
                    key={nav.id}
                    className={`${
                      active === nav.title ? 'text-primary' : 'text-butterCream'
                    } text-[2em] font-mova  mt-[0.5em]
                      uppercase leading-[1.5em] cursor-pointer`}
                    onClick={() => {
                      setToggle(!toggle);
                      setActive(nav.title);
                    }}>
                    <a href={`#${nav.id}`}>{nav.title}</a>
                  </li>
                ))}
              </ul>
              </div>
            </motion.div>
          ) : (
            <FontAwesomeIcon 
              className="text-primary text-[1.5em] cursor-pointer z-20"  
              onClick={() => setToggle(!toggle)} icon={faBars} 
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { styles } from '../styles';
import { fadeIn, slideIn } from '../lib/motion';
import { github, linkedin } from '../assets/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright, faEnvelope, faEnvelopeCircleCheck,faLocationDot, faMobile, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody, ModalContent, ModalTrigger } from '../ui/animated-modal';
import { ContactModal } from '../ui/contact-modal';
import { emailjsKeys } from '../model/constants';

const Contact = () => {
  const formRef:any = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const mailID = 'mailto:shrushti.mane01@gmail.com';
  const callID = 'tel:+91 8149849993';

  const validateForm = (data:any) => {
    let errors = { 
      name: '',
      email: '',
      message: ''
    };
        
      if (!data.name.trim()) {
          errors.name = 'Username is required';
      } else if (data.name.length < 4) {
          errors.name = 'Username must be at least 4 characters long';
      }

      if (!data.email.trim()) {
          errors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Email is invalid';
      }

      if (!data.message) {
          errors.message = 'Message is required';
      } else if (data.message.length < 10) {
          errors.message = 'Message must be at least 8 characters long';
      }

    return errors;
  }

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    const newErrors = validateForm(form);
    setErrors(newErrors);
    setForm({ ...form, [name]: value });
  };

  const openSite = (site: string) => {
    switch(site){
      case "linkedin":
        window?.open("https://www.linkedin.com/in/shrushtimane/", "_blank")?.focus();
      break;
      case "github":
        window?.open("https://github.com/ManeShrushti", "_blank")?.focus();
      break;
      case "email":
        window.location.href = mailID
      break;
      case "mobile":
        window.location.href = callID
      break;
    }
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setLoading(true);
    if(form.name.trim() && form.email.trim() && form.message.trim() && (errors.name==="" && errors.message==="" && errors.email === "")){
      emailjs
      .send(
        emailjsKeys.service_id, 
        emailjsKeys.template_id, 
        {
          from_name: form.name,
          to_name: 'Shrushti',
          from_email: form.email,
          to_email: 'shrushti.mane01@gmail.com',
          message: form.message,
          subject: `Portfolio Reach | ${form.name}`
        },
        emailjsKeys.public_key
      )
      .then(
        () => {
          setLoading(false);
          alert('Thank you! I will get back to you within the next 48 hours.');
          setForm({
            name: '',
            email: '',
            message: '',
          });
          setErrors({
            name: '',
            email: '',
            message: '',
          })
        },
        (error:any) => {
          setLoading(false);
          console.log(error);
          alert('Something went wrong. Please try again.');
        }
      );
    }
    
  };

  return (
    <div id="contact"
        className="pt-16 lg:flex-row flex-col w-full flex overflow-hidden bg-contact bg-fit bg-cover">
      <motion.div
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={fadeIn('right', 'tween', 0.1, 0.5)}
        className="flex flex-col flex-[0.5] ml-4 sm:ml-8 mr-4 sm:mr-8 mb-4 contact-card-background p-8 rounded-2xl">
        <p className={styles.sectionSubTextLight}>Get In Touch</p>
        <h3 className={styles.sectionHeadLight}>I'd like to hear from you!</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-5 flex flex-col gap-3 font-poppins">
          <label className="flex flex-col">
            <span className="text-timberWolf font-medium mb-4">Your Name</span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="What's your name?"
              className="bg-jet py-2 px-6
              placeholder:text-taupe
              text-timberWolf rounded-lg outline-none
              border-none font-medium"
            />
             {errors.name && <p className="text-red-600 text-sm pt-2">{errors.name}</p>}
          </label>
          <label className="flex flex-col">
            <span className="text-timberWolf font-medium mb-4">Your Email</span>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="What's your email?"
              className="bg-jet py-2 px-6
              placeholder:text-taupe
              text-timberWolf rounded-lg outline-none
              border-none font-medium"
            />
             {errors.email && <p className="text-red-600 text-sm pt-2">{errors.email}</p>}
          </label>
          <label className="flex flex-col">
            <span className="text-timberWolf font-medium mb-4">
              Your Message
            </span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What's your message?"
              className="bg-jet py-2 px-6
              placeholder:text-taupe
              text-timberWolf rounded-lg outline-none
              border-none font-medium resize-none h-[8em]"
            />
             {errors.message && <p className="text-red-600 text-sm pt-2">{errors.message}</p>}
          </label>

          <button
            type="submit"
            disabled={form.name === "" || form.email === "" || form.message === "" || errors.name!== "" || errors.email !== "" || errors.message !== ""}
            className="flex justify-center sm:gap-4 
            gap-3 sm:text-[20px] text-[16px] text-timberWolf 
            font-bold font-poppins items-center py-5
            whitespace-nowrap sm:w-[130px] sm:h-[50px] 
            w-[100px] h-[45px] rounded-[1em] bg-night 
            hover:bg-primary hover:text-eerieBlack disabled:bg-night disabled:cursor-not-allowed
            transition duration-[0.2s] ease-in-out cursor-pointer"
            onClick={handleSubmit}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin/> : <FontAwesomeIcon icon={faEnvelopeCircleCheck} />}
            {loading ? 'Sending' : 'Send'}
            
          </button>
        </form>
      </motion.div>

      <div className="flex flex-col flex-[0.5] ml-2 sm:ml-0 p-8 ">
          <div className={styles.sectionSubTextLight}>Contact</div>
          <div className="flex flex-col md:w-full md:h-full gap-3 mt-5">
              <div className='flex flex-row'>
                  <a href={mailID} className='w-12 text-2xl hover:scale-105 text-primary content-center'><FontAwesomeIcon icon={faEnvelope} onClick={() => openSite("email")}/>  </a>   
                  <div className={styles.sectionHighlight}>shrushti.mane01@gmail.com</div>
                 
              </div>
              <div className='flex flex-row'>
                  <a href={callID} className='w-12 text-2xl hover:scale-105 text-primary content-center'><FontAwesomeIcon icon={faMobile} onClick={() => openSite("email")}/>  </a>
                  <div className={styles.sectionHighlight}>+91 9325962231</div>
                  
              </div>
              <div className='flex flex-row'>
                  <i className='text-2xl w-12 text-primary hover:scale-105 content-center'><FontAwesomeIcon icon={faLocationDot} onClick={() => openSite("email")}/>  </i>
                  <div className={styles.sectionHighlight}>Pune, India</div>
                  
              </div>
             
              
          </div>

        
          
          <div className="mt-20 flex flex-1 z-10 md:w-full md:h-full space-x-2 md:space-x-4 items-center">
            
            <img
              alt="github"
              src={github}
              className="cursor-pointer hover:scale-105 w-10 h-10 rounded-full"
              onClick={() => openSite("github")}
            />
            <img
              alt="linkedin"
              src={linkedin}
              className="cursor-pointer hover:scale-105 w-10 h-10 rounded-full"
              onClick={() => openSite("linkedin")}
            />
          </div>
          <div className="mt-5 text-base md:text-lg">
              Driven by curiosity, fueled by innovation.
          </div>
          <div className="mt-2 text-timberWolf md:text-sm">
              <FontAwesomeIcon icon={faCopyright} /> 2024 Shrushti Mane. 
              <Modal>
                <ModalTrigger className="ml-2 underline font-bold cursor-pointer text-timberWolf md:text-sm">
                  <span className="text-center transition duration-500 underline-offset-1">
                    Crafted by yours truly!
                  </span>
                </ModalTrigger>
                <ModalBody className='bg-modalCover w-22 h-22 text-butterCream'>
                  <ModalContent>
                        <ContactModal/>
                  </ModalContent>
                </ModalBody>
              </Modal>
          </div>
      </div>
    
    
    </div>
  );
};

export default Contact;

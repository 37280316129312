const styles = {
  heroHeadText:
    'font-black text-eerieBlack xl:text-[2.5em] lg:text-[2.5em] sm:text-[2em] xs:text-[2em] text-[2em] lg:leading-[50px] mt-2',
  heroSubText:
    'text-eerieBlack font-medium xl:text-[1.5em] lg:text-[1.3em] sm:text-[1.3em] xs:text-[1.3em] text-[1.2em] lg:leading-[1.2em]',
  sectionHeadText:
    'text-primary font-black md:text-[2.5em] sm:text-[2em] xs:text-[1.5em] text-[2em] font-poppins',
  sectionHeadTextLight:
    'text-timberWolf font-black md:text-[3em] sm:text-[2.5em] xs:text-[2em] text-[1.5em] font-poppins float-left',
  sectionHeadLight:
    'text-primary font-black md:text-[1.5em] sm:text-[1.2em] xs:text-[0.8em] text-[1.5em] font-poppins',
  sectionSubText:
    'sm:text-[2em] text-[1em] text-taupe uppercase tracking-wider font-semibold font-poppins',
  sectionSubTextLight:
    'sm:text-[1.2em] text-[1em] text-taupe uppercase tracking-wider font-semibold font-poppins',
  sectionTitleLight:
    'sm:text-[1em] text-[1.2em] text-taupe tracking-wider font-semibold font-poppins',
  sectionHighlight:
    'text-butterCream content-center md:text-[1.2em] sm:text-[0.5em] xs:text-[0.5em] text-[1.2em] font-poppins',
};

export { styles };

import { motion } from "framer-motion";
import { useState } from "react";
import { cn } from "../lib/utlis";
import { AnimatedTooltip } from "./animated-tooltip";
import { css,html,typescript,angular,angularjs,bootstrap,axios,ant,material,mongodb,express,echarts,formly,redux,reactjs,nodejs,tailwind,webpack, querybuilder, ngrx, d3} from "../assets/assets";
import { technologiesValues } from "../model/constants";

type CardType = {
  src: string;
  title: string;
  category: string;
  link: string;
  description: string;
  features?: string[],
  stack: string[];
};
export const Card = ({
  card,
  index,
  layout = false,
}: {
  card: CardType;
  index: number;
  layout?: boolean;
}) => {
  const techImage = {
    css,html,typescript,angular,angularjs,ant,material,bootstrap,axios,mongodb,express,echarts,ngrx,d3,formly,querybuilder,redux,reactjs,nodejs,tailwind,webpack
  };

  const techStack = card.stack.map((es, index) => {
    const formattedKey = es.replace(/\s+/g, '') as keyof typeof techImage; // Ensure the key matches

    return {
      id: index,
      name: technologiesValues[es], // Replace underscores with spaces for display
      image: techImage[formattedKey] || '', // Provide a fallback image if key doesn't exist
    };
  });


  const [isFlipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!isFlipped);
  };

  return (
    <>
      <motion.div
        layoutId={layout ? `card-${card.title}` : undefined}
        whileHover={{
          y: -15,
        }}
        style={{
          transformStyle: "preserve-3d",
          transition: "0.8",
          perspective: "1000px",
        }}
        onClick={handleFlip}
        className="rounded-3xl bg-transparent dark:bg-neutral-900 h-[25rem] w-[25rem] md:h-[28rem] md:w-[28rem] xl:[h-35rem] xl:w-[35rem] overflow-hidden flex flex-col items-start justify-start relative z-10"
      >
        <motion.div
          className={`absolute h-full w-full rounded-3xl bg-transparent dark:bg-neutral-900 flex flex-col items-center justify-center p-4`}
          animate={{ rotateY: isFlipped ? 180 : 360 }}
          style={{ transformStyle: "preserve-3d" }}
          transition={{ duration: 0.8 }}
        >
          <div className="absolute h-full top-0 inset-x-0 bg-gradient-to-t from-black/30 via-transparent to-transparent z-30 pointer-events-none" />
          <div
            className="w-full h-full"
            style={{ backfaceVisibility: "hidden", position: "absolute" }}
          >
            <div className="relative z-40 p-8">
              <motion.p
                layoutId={layout ? `title-${card.title}` : undefined}
                className="text-primary text-xl md:text-3xl font-semibold max-w-xs text-left [text-wrap:balance] font-sans mt-2"
              >
                {card.title}
              </motion.p>
            </div>
            <BlurImage
              src={card.src}
              alt={card.title}
              fill="true"
              className="object-cover absolute inset-0"
            />
          </div>
          <div
            className="w-full h-full"
            style={{
              backfaceVisibility: "hidden",
              position: "absolute",
              transform: "rotateY(180deg)",
            }}
          >
            <div className="flex flex-col justify-between items-left relative z-40 p-8 bg-dutchBlue h-full">
              <motion.p
                layoutId={layout ? `title-${card.title}` : undefined}
                className="text-butterCream text-xl md:text-3xl font-semibold max-w-xs text-left [text-wrap:balance] font-sans mt-2"
              >
                {card.title}
              </motion.p>
              <motion.div
                layoutId={layout ? `title-${card.title}` : undefined}
                className="text-butterCream text-md text-left font-medium w-full [text-wrap:balance] font-sans mt-2"
              >
                {card.description}
                {card.features && (
                  <div className="text-left mt-4">
                    <span className="font-bold text-lg">Key Deliverables:</span>
                    <ul>
                      {card.features.map((ft)=>(<li>
                        {ft}
                      </li>))}
                    </ul>
                </div>)}
              </motion.div>
              <div className="relative flex flex-row justify-between mt-4 ">
                <div className="flex flex-1 justify-start">
                  <AnimatedTooltip items={techStack} />
                </div>
                { card.link && 
                  <div className="flex items-center ml-2">
                    <a href={card.link} target="_blank" rel="noreferrer"><span className="text-primary">View Project</span></a>
                  </div> 
                }
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export const BlurImage = ({
  height,
  width,
  src,
  className,
  alt,
  ...rest
}: any) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <img
      className={cn(
        "transition duration-300 rounded-3xl w-full h-full object-cover",
        isLoading ? "blur-sm" : "blur-0",
        className
      )}
      onLoad={() => setLoading(false)}
      src={src}
      width={width}
      height={height}
      loading="lazy"
      decoding="async"
      blurdataurl={typeof src === "string" ? src : undefined}
      alt={alt ? alt : "Background"}
      {...rest}
    />
  );
};

import { motion } from 'framer-motion';
import { styles } from '../styles';
import { textVariant } from '../lib/motion';
import { services } from '../model/constants';
import { ServiceCard } from '../ui/service-card';

const Services = () => {
  const titleVariant = textVariant(0.1);
  return (
    <div id="about" className="flex flex-col md:flex-row py-14 sm:py-16 bg-services bg-center bg-cover h-full md:h-[95vh] w-full md:w-full services-border"  >
        <div className='ml-8 sm:ml-10  mr-14 sm:mr-12 mb-14 sm:mb-14'>
            <motion.div  
                initial="hidden"
                whileInView="show" 
                viewport={{ once: true }}
                variants={titleVariant}>
                <p className={styles.sectionSubTextLight}>
                    Services
                </p>
            </motion.div>
            <motion.div  
                initial="hidden"
                whileInView="show" 
                viewport={{ once: true }}
                variants={titleVariant}>
                <p className={styles.sectionHeadText}>
                   what can I do
                </p>
            </motion.div>

            <div className="mt-4 gap-4 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full h-[70%]">
                {services.map((service:any, index:number) => (
                <ServiceCard key={service.title} index={index} {...service} />
                ))}
            </div>

        </div>
    </div>
  );
};

export default Services;

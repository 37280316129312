import { faCircleDown, faFileText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DownloadButton = (props:any) => {
    return (
        
        <button className="bg-transparent w-full content-center no-underline group cursor-pointer relative shadow-3xl shadow-zinc-900 rounded-[1em] p-px text-xs font-semibold leading-6  text-white inline-block">
            <span className="absolute inset-0 overflow-hidden rounded-[1em]">
            <span className="absolute inset-0 rounded-[1em] bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(0,10,10,0.6)_0%,rgba(0,238,255,0.2)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span>
            </span>
            <div className="relative flex space-x-2 items-center justify-center z-10 text-[1.3em] rounded-[1em] h-[3em] bg-transparent-950 py-0.5 px-4 ring-2 ring-white/10 ">
            <FontAwesomeIcon icon={props.icon === 'file' ? faFileText : faCircleDown}/>
            <span>{props.text}</span>
            </div>
            <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-primary-400/0 via-night-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
        </button>

    );
  }
  
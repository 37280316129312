import { motion } from "framer-motion";
import { zoomIn } from "../lib/motion";

export const SkillCard = ({tech,index}:{tech:any,index:number}) => {
    const cardVariants = zoomIn(0.1 * index, 0.5);
    return (
      <motion.div 
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        variants={cardVariants}
        className="w-[3em] h-[3em] sm:w-[5em] sm:h-[5em] p-2 sm:p-4">
          <div className="skill-icon">
            <img alt={tech.name} className="rounded-[50%]"src={tech.icon}/>
          </div>
      </motion.div>
    );
  
};

export const ContactModal = () => {
    return (
      <>
        <div className="flex flex-col">
            <h4 className="text-lg md:text-2xl font-bold text-center mb-4">
                And the credit goes to...                
            </h4>
            <div className='md:text-sm font-bold text-center mb-8'>
                Here I explain the technology, skills and graphic assets used to craft this site.
            
            </div>
       
      
        <div className='flex flex-col gap-4 text-center justify-evenly'>
            <p className='whitespace-pre'>
                DESIGN & DEVELOPMENT <br/>
                <br/>
                Shrushti Mane <br/>
                Hand crafted design & web development<br/>
                <br/>
                github.com/ManeShrushti
            </p>
            <br/>
            <hr/>
            <div className="flex flex-row text-center justify-evenly p-2">
                <div className="flex-1 border-r-[1px]">
                    TECHNOLOGY <br/>
                    <br/>
                    <ul>
                        <li>React</li>
                        <li>Aceternity UI</li>
                        <li>Tailwind CSS</li>
                        <li>Framer Motion</li>
                        <li>Spline</li>
                    </ul>
                </div>
                <div className="flex-1 border-r-[1px]">
                    FONTS <br/>
                    <br/>
                    <ul>
                        <li>Arenq</li>
                        <li>Poppins</li>
                        <li>Mova</li>
                        <li>Wadoend</li>
                    </ul>
                </div>
                <div className="flex-1">
                    Assets <br/>
                    <br/>
                    <ul>
                        <li>Pinterest</li>
                        <li>Dribble</li>
                        <li>Behance</li>
                        <li>TechIcons.dev</li>
                        <li>ChatGPT</li>
                    </ul>
                </div>
            </div>
          
            </div>
        </div>
        
      </>
    );
  }
  
import React from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { Carousel } from "./carousel";
import { Card } from "./card";
import { styles } from '../styles';
import { textVariant } from "../lib/motion";

export const Parallax = ({ projects }: { projects: any[] }) => {
  const firstRow = projects.slice(0, 5);
  const ref = React.useRef(null);
  const divRef = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const springConfig = { stiffness: 200, damping: 30, bounce: 100 };
  const rotateX = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [20, 0]),
    springConfig
  );
  const opacity = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [0.2, 1]),
    springConfig
  );
  const rotateZ = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [16, 0]),
    springConfig
  );
  const translateY = useSpring(
    useTransform(scrollYProgress, [0, 0.01], [-400, 0]),
    springConfig
  );
  const cards = firstRow.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));
  return (
    <div
      id="projects"
      ref={ref}
      className="pb-auto overflow-hidden items-center antialiased relative flex flex-col self-auto [perspective:1000px] [transform-style:preserve-3d] bg-gradient-to-t from-dutchBlue/50"
    >
      <Header />
      <motion.div
        style={{
          rotateX,
          rotateZ,
          translateY,
          opacity,
        }}
        ref={divRef}
        className="w-[98%] overflow-hidden mr-10 ml-10 mt-auto"
      >
        <motion.div className="flex justify-center w-screen mb-5 space-x-8 ">
          <Carousel items={cards} />
        </motion.div>
      </motion.div>
    </div>
  );
};

export const Header = () => {
  const titleVariant = textVariant(0.1);
  return (
    <div className="max-w-7xl mx-[4rem] py-10 md:py-20 px-4 w-full z-10 mr-10">
      
      <motion.div  
        initial="hidden"
        whileInView="show" 
        viewport={{ once: true }}
        variants={titleVariant}>
        <p className={styles.sectionSubTextLight}>
            Projects
        </p>
      </motion.div>
      <motion.div  
          initial="hidden"
          whileInView="show" 
          viewport={{ once: true }}
          variants={titleVariant}>
          <p className={styles.sectionHeadText}>
              creations
          </p>
      </motion.div>
      <p className="max-w-2xl text-base md:text-xl mt-8 text-butterCream">
        I build beautiful applications with the latest technologies and frameworks.
        <br />
        <br/> 
        My work reflects a blend of innovation and practicality,
        consistently pushing the boundaries of what's possible in tech.
        <br/>
        These practical examples demonstrate my expertise in handling complex problems, adapting to diverse technologies, and managing projects effectively.
        <br /> 
        <br /> 
        I thrive on turning complex challenges into elegant,
        user-focused solutions that made a real difference.

      </p>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/Main';
import Navbar from './components/Navbar';
import Work from './components/Work';
import About from './components/About';
import Services from './components/Services';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Loader from './ui/loader';

function App() {
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  useEffect(() => {
    // Simulate loading time for the Spline model
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after the model is "loaded"
    }, 1000); // Adjust the time based on your actual model loading time

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, [isLoading]);

  return (
    <BrowserRouter>
      <div className="">
        {isLoading && <Loader />}
        {!isLoading && 
        <div className='flex flex-col bg-night h-full w-full overflow-hidden '>
          <Navbar/>
          <Main />
          
          <div className='bg-aboutCover bg-cover w-full'>
            <About/>
          </div>
          <div className='bg-servicesCover w-full'>
            <Services/>
          </div>
          {/* <Experience/> */}
          <Skills/>
          <div className='bg-workCover'>
            <Work/>
          </div>
          <Contact/>
        </div>}
      </div>
    </BrowserRouter>
  );
}

export default App;

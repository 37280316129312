
import {
  css,
  git,
  html,
  javascript,
  nodejs,
  reactjs,
  redux,
  tailwind,
  typescript,
  angular,
  ant,
  ngrx,
  webpack,
  express,
  mongodb,
  aggrid,
  formly,
  angularjs,
  d3,
  echarts,
  material,
  querybuilder,
  microverse,
  weather,
  xpensio,
  datagenerator,
  algolibrary,
  bootstrap,
  dataviz,
  jenkins,
  jira,
  confluence,
  axiosT,
  d3T,
  reduxT,
  ngrxT,
  jest,
  sass
} from '../assets/assets';

export const navLinks = [
  {
    id: 'home',
    title: 'Home',
  },
  {
    id: 'about',
    title: 'About',
  },
  {
    id: 'skills',
    title: 'Skills',
  },
  {
    id: 'projects',
    title: 'Projects',
  },
  {
    id: 'contact',
    title: 'Contact',
  },
];


const services = [
  {
    title: 'Web Development',
    icon: 'faLaptopCode',
    description: 'Building interactive web-applications focused on functionality, scalability, reliablity to provide impactful digital experiences.'
  },
  {
    title: 'Creative Designs',
    icon: 'faWandMagicSparkles',
    description: 'Utilizing artistic elements and design principles to create visually appealing, captivating, and immersive user experiences.'
  },
  {
    title: 'User Experience',
    icon: 'faPersonRays',
    description: 'Analyzing user behaviors, needs, and preferences to design workflows that are intuitive, efficient, seamless, and enjoyable to use.'
  },
  {
    title: 'Clean Code and Support',
    icon: 'faCode',
    description: 'Delivering well-structured, maintainable code with fast, responsive support for user issues to, ensure smooth development and satisfaction.'
  },
];

const technologies = [
  {
    name: 'HTML 5',
    icon: html,
  },
  {
    name: 'CSS 3',
    icon: css,
  },
  {
    name: 'JavaScript',
    icon: javascript,
  },
  {
    name: 'TypeScript',
    icon: typescript,
  },
  {
    name: 'Angular',
    icon: angular,
  },
  {
    name: 'Angular JS',
    icon: angularjs,
  },
  {
    name: 'Angular',
    icon: angular,
  },
  {
    name: 'Node.js',
    icon: nodejs,
  },
  {
    name: 'Express.js',
    icon: express,
  },
  {
    name: 'Mongo DB',
    icon: mongodb,
  },
  {
    name: 'Ant Design',
    icon: ant,
  },
  {
    name: 'Bootstap',
    icon: bootstrap,
  },
  {
    name: 'Material UI',
    icon: material,
  },
  {
    name: 'Tailwind CSS',
    icon: tailwind,
  },
  {
    name: 'AG Grid',
    icon: aggrid,
  },
  {
    name: 'Formly',
    icon: formly,
  },
  {
    name: 'ECharts',
    icon: echarts,
  },
  {
    name: 'D3.js',
    icon: d3,
  },
  {
    name: 'React JS',
    icon: reactjs,
  },
  {
    name: 'Redux Toolkit',
    icon: redux,
  },
  {
    name: 'NgRx',
    icon: ngrx,
  },
  {
    name: 'Webpack',
    icon: webpack,
  },
  {
    name: 'Angular Query Builder',
    icon: querybuilder
  }
 
];


const technologiesValues: Record<string, string>= {
  html: 'HTML 5',
  css: 'CSS 3',
  javascript:'JavaScript',
  typescript: 'TypeScript',
  angular: 'Angular',
  angularjs: 'Angular JS',
  nodejs:'Node.js',
  express : 'Express.js',
  mongodb: 'Mongo DB',
  ant: 'Ant Design',
  bootstrap: 'Bootstrap',
  material: 'Material UI',
  tailwind: 'Tailwind CSS',
  aggrid: 'AG Grid',
  formly: 'Angular Formly',
  echarts:'ECharts',
  d3:'D3.js',
  reactjs: 'React JS',
  redux: 'Redux Toolkit',
  ngrx: 'NgRx',
  axios: 'Axios',
  webpack: 'Webpack',
  querybuilder: 'Angular Query Builder'
};

const experiences = [
  {
    title: 'Engineering Lead',
    company_name: 'ABC',
    icon: microverse,
    iconBg: '#333333',
    date: 'Apr 2024 - Present',
  },
  {
    title: 'UI Lead',
    company_name: 'XYZ',
    icon: microverse,
    iconBg: '#333333',
    date: 'May 2023 -  Mar 2024',
  },
  {
    title: 'Senior Software Engineer',
    company_name: 'QIO',
    icon: microverse,
    iconBg: '#333333',
    date: 'Apr 2022 - Apr 2023',
  },
  // {
  //   title: 'Software Developer',
  //   company_name: 'Diversity Cyber Council',
  //   icon: dcc,
  //   iconBg: '#333333',
  //   date: 'Feb 2021 - Mar 2022',
  // },
  // {
  //   title: 'Software Associate ',
  //   company_name: 'Diversity Cyber Council',
  //   icon: dcc,
  //   iconBg: '#333333',
  //   date: 'Jun 2019 - Jan 2021',
  // },
];

const expertise = [
  {
    name: 'HTML 5',
    icon: html,
  },
  {
    name: 'CSS 3',
    icon: css,
  },
  
  {
    name: 'JavaScript',
    icon: javascript,
  },
  {
    name: 'TypeScript',
    icon: typescript,
  },
  {
    name: 'Angular JS',
    icon: angularjs,
  },
  {
    name: 'Angular',
    icon: angular,
  },
  {
    name: 'React JS',
    icon: reactjs,
  },
 
  {
    name: 'Node.js',
    icon: nodejs,
  },
  {
    name: 'Express.js',
    icon: express,
  },
  {
    name: 'Mongo DB',
    icon: mongodb,
  },
  
];

const otherSkills = [
  {
    name: 'Ant Design',
    icon: ant,
  },
  {
    name: 'Material UI',
    icon: material,
  },
  {
    name: 'Tailwind CSS',
    icon: tailwind,
  },
  {
    name: 'Sass',
    icon: sass,
  },
  {
    name: 'Axios',
    icon: axiosT
  },
  {
    name: 'ECharts',
    icon: echarts,
  },
  {
    name: 'D3.js',
    icon: d3T,
  },
  {
    name: 'Jest',
    icon: jest
  },
  {
    name: 'Redux',
    icon: reduxT,
  },
  {
    name: 'NgRx',
    icon: ngrxT,
  },
  {
    name: 'Git',
    icon: git
  },
  {
    name: 'Jenkins',
    icon: jenkins
  },
  {
    name: 'Jira',
    icon: jira
  },
  {
    name: 'Confluence',
    icon: confluence
  },
 
];

const softSkills = [
  {
    name: 'Communication',
    value: 95,

  },
  {
    name: 'Teamwork',
    value: 85
  },
  {
    name: 'Adaptability',
    value: 88,
  },
  {
    name: 'Leadership',
    value: 83,
  },
  {
    name: 'Problem-solving',
    value: 92,
  },
];


const projects = [
  {
    title: "Algorithm Pipeline",
    category: "Manufacturing Industries",
    src: algolibrary,
    description: 'A no-code suite built at work to streamline sequential execution of in-house AI-ML algorithms on real-time IoT data, making it an efficient solution for data processing, analysis, and task automation. This suite was developed using minimal HTML code effort driving all the dynamic form generation through user actions, providing a seamless and intuitive experience for managing complex workflows.',
    stack: ["angular","typescript","ant","d3","formly","redux"]
  },
  {
    title: "Data Visualization",
    category: "Manufacturing Industries",
    src: dataviz,
    description: 'An extensive suite developed at work for creating customizable widgets and dashboards, offering real-time data insights through a simple drag-and-drop interface. Integrating diverse data, it enables dynamic updates and tailored visualizations to meet diverse business needs, empowering users of all skill levels to analyze trends, monitor KPIs, and make data-driven decisions.',
    stack: ["angular","typescript","ant","formly","redux","echarts","ngrx","mongodb"]
  },
  // {
  //   title: "Rule Builder based Alerts System",
  //   category: "Manufacturing Industries",
  //   link: "https://www.qio.io/industrial-manufacturing",
  //   src: rulebuilder,
  //   description: 'A system designed and developed to monitor and trigger alerts based on user-defined conditions and rules. By setting specific criteria and queries, users can automate real-time monitoring across data sources, receiving instant notifications when conditions are met. This system allows for granular control over alert parameters, enabling proactive decision-making and rapid response to key events, anomalies, or threshold breaches within the data.',
  //   stack: ["angular","typescript","ant","formly","querybuilder"]
  // },
  // {
  //   title: "ASC",
  //   category: "Productivity",
  //   link: "https://cursor.so",
  //   src: asc,
  //   description: 'A secure, responsive web application developed for Rolls-Royce to connect over 100 service centers worldwide, streamlining core business operations for aircraft engine servicing. It efficiently manages extensive data directories, gathers actionable insights, and enhances communication across locations.',
  //   features: ["Streamlining the translation of client specification","Troubleshooting and resolving issues","Quality Control with Pre-Release Testing","Cross-Browser and Device Compatibility","..and many more"],
  //   stack: ["angularjs", "bootstrap", "mongodb"]
  // },
  {
    title: "Xpensio",
    category: "Projects",
    link: "https://xpensio-eaoh.onrender.com/",
    description: "Xpensio is a comprehensive expense tracking platform that simplifies financial management. It offers intuitive interfaces for logging expenses, categorizing transactions, and generating detailed reports, allowing users to monitor spending patterns, set budgets, and access real-time analytics.",
    src: xpensio,
    stack: ["reactjs", "typescript", "mongodb","express","ant","tailwind"]
  },
  {
    title: "WeatherCast",
    category: "Weather App",
    link: "https://weather-app-b0tj.onrender.com",
    description: "Location-Based Weather Forecast is a web application that delivers real-time weather updates for user-selected locations. It features an intuitive interface for viewing detailed forecasts, including temperature, humidity, and wind speed, by integrating with the Foreca Weather API to provide accurate data.",
    src: weather,
    stack: ["reactjs", "typescript","axios", "material","tailwind"]
  },
  {
    title: "Data Generator",
    category: "Data Generator",
    link: "https://github.com/ManeShrushti/data-generator",
    description: "Data Generator is an API built on Express.js that simplifies the creation of random timestamp-based data for testing and prototyping. This project utilizes Express.js alongside validations to generate data values of specified data types, randomly selecting them within defined ranges or from provided sets of values.",
    src:
      datagenerator,
    stack: [
        "nodejs",
        "express",
    ]
  },
];
const emailjsKeys = {
  service_id: 'service_portfolio',
  template_id: 'portfolio_1a2x3z4c',
  public_key: 'NEhyTZ30OxeE6BW7R'
}
const flipWords = ["innovative", "seamless","responsive", "modern", "engaging", "resilient"];
export { services, technologies,technologiesValues, experiences, projects,flipWords,otherSkills,softSkills,expertise,emailjsKeys};


import { loader } from '../assets/assets';
import { motion } from 'framer-motion';

const Loader = () => {
  return (
    <div className="loader-container bg-contact bg-cover bg-center">
      <div className="loader flex flex-col justify-center items-center w-64 h-64 sm:w-80 sm:h-80 md:w-96 md:h-96 lg:w-[25rem] lg:h-[25rem]">
        <img alt="loader" src={loader} className="w-auto h-auto" loading='eager'/>
        <motion.div
            animate={{ opacity: [1, 0, 1] }}
            transition={{
                duration: 1, 
                repeat: Infinity,
                repeatType: 'loop', 
                ease: 'easeInOut',
            }}
            style={{ fontSize: '1.8rem', fontWeight: 'bold', textAlign: 'center' }}
            >
           Loading ...
        </motion.div>
      </div>
    </div>
  );
};

export default Loader;
import { motion } from 'framer-motion';
import { slideIn } from '../lib/motion';

export const ProgressBar = (props:any) => {
  const barVariant = slideIn('right','keyframes',0.1 * props.index, 0.5);
    return (
      <motion.div  
      initial="hidden"
      whileInView="show" 
      viewport={{ once: true }}
      variants={barVariant}
      className="progress-bar">
            <div className="progress-bar-completed" style={{ width: `${(props.value / 100) * 100}%` }}>
            </div>
      </motion.div>
    );
  }
  
import { motion } from 'framer-motion';
import { expertise, otherSkills, softSkills } from '../model/constants';
import { styles } from '../styles';
import { fadeIn, textVariant } from '../lib/motion';
import { SkillCard } from '../ui/skill-card';
import { ProgressBar } from '../ui/progress-bar';

const Skills = () => {
  const titleVariant = textVariant(0.1);
  const subTextVariant = fadeIn('', 'spring', 0.1, 0.5);
  return (
    <>
      <div id="skills" className='bg-skills bg-repeat bg-cover flex flex-col md:flex-col pt-12 pb-8 px-10 h-full md:h-screen w-full md:w-full '>
        <motion.div  
          initial="hidden"
          whileInView="show" 
          viewport={{ once: true }}
          variants={titleVariant}>
          <p className={styles.sectionSubTextLight}>
              Skills
          </p>
        </motion.div>
       
        
        <div className='flex flex-col md:flex-row w-full'>
          <div className='sm:basis-full w-full sm:w-[50%]'>
            <div>
              <p className={styles.sectionHeadText}>
                  toolbox
              </p>
            </div>

            <div className="grid grid-cols-5 gap-2 justify-center mt-2 w-full">
              {expertise.map((technology,index) => (
                <div className="flex flex-col sm:flex-col justify-center items-center text-center" key={technology.name}>
                  <div className="tech-icon-bg flex justify-center items-center">
                    <SkillCard tech={technology} index={index}/>
                  </div>
                  <div className="text-xs sm:text-sm mt-2">
                    {technology.name}
                  </div>
                </div>
              ))}
            </div>



            <motion.div  
              initial="hidden"
              whileInView="show" 
              viewport={{ once: true }}
              variants={subTextVariant}
              className='mt-10 text-center'>
              <p className={styles.sectionTitleLight}>
                  Wait.. there's more
              </p>
            </motion.div>
            <div className="flex flex-wrap bg-oxfordBlue justify-center p-2 gap-1 mt-3 rounded-md">
              {otherSkills.map((skill) => (
                  <div key={skill.name} className='flex flex-col  justify-center text-center'>
                    <div className='flex flex-col p-1'>
                        <div className="w-10 h-10">
                          <div className="other-skill">
                            <img src={skill.icon} alt={skill.name}/>
                          </div>
                        </div>
                    </div>
                  </div>
              ))}
            </div>
          </div>
         
        
          <div className='flex flex-col basis-full justify-start mt-4 sm:mt-0 ml-0 sm:ml-20 sm:basis-full w-full sm:w-[50%]'>
            <div className='mb-4'>
              <p className={styles.sectionHeadText}>
                  aura
              </p>
            </div>
                {softSkills.map((skill:any,idx:number) => (
                  <>
                    <div className='flex flex-col justify-between text-left'>
                      <div className="w-[90%] px-4 mb-5" key={skill.name}>
                        {/* <BallCanvas icon={technology.icon} /> */}
                        <div className='text-sm mb-2'>
                          {skill.name}
                        </div>
                        <ProgressBar value={skill.value} index={idx}/>
                      </div>
                     
                    </div>
                  </>
                ))}
          
          </div>
          </div>
       
      </div>
    
    </>
  );
};

export default Skills;

import { Parallax } from '../ui/parallax';
import { projects } from '../model/constants';
const Work = () => {
  return (
      <div id="projects" >
        <Parallax projects={projects} />
      </div>
      
  )
}

export default Work;

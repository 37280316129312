import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fadeIn } from '../lib/motion';
import { motion } from 'framer-motion';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const ServiceCard = (props:any) => {
    const cardVariants = fadeIn('right', 'spring', 0.5 * props.index, 0.75);
    
    const IconComponent = ({ iconName }: { iconName: keyof typeof Icons }) => {
      const icon = Icons[iconName] as IconDefinition; // Type assertion

      return <FontAwesomeIcon icon={icon} className="text-primary text-3xl sm:text-5xl" />;
    };
    return (
      <motion.div
        initial="hidden"
        whileInView="show" 
        viewport={{ once: true }}
        variants={cardVariants}
        className="w-full h-full card-gradient p-[1px] rounded-[3em] shadow-card mt-4">
        <div
          className="bg-jet w-full rounded-[3em] px-2 py-4 md:py-4 md:px-8 h-full flex justify-evenly items-center flex-col">
          <IconComponent iconName={props.icon}/>
          <h3 className="text-taupe text-[1.1rem] sm:text-[1.2rem] lg:text-[1.2rem] font-bold text-center mt-4">
            {props.title}
          </h3>
          <p className="text-taupe text-[0.8rem] sm:text-[1rem] lg:[1.2rem] text-center">
            {props.description}
          </p>
        </div>
      </motion.div>
    );
};